var GLOBAL = {
    easing: [0.55, 0.05, 0.22, 0.99],
};
loadYoutubeApi();

jQuery(function () {
    var globalHeader = $(".global-header");
    let uiColor = $(".banner-item").eq(0).data("ui-color");
    globalHeader.attr("data-ui-color", uiColor);
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
            globalHeader.attr("data-ui-color", "");
            $(".menu-toggle").find("em").toggleClass("fa-bars fa-xmark");
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
            globalHeader.attr("data-ui-color", uiColor);
            $(".menu-toggle").find("em").toggleClass("fa-bars fa-xmark");
        },
    });

    searchToggle();
    headerSubMenuToggle();
    onScrollInit();
    newsSelectorTrigger();
    sideBarInit();
    lenseFilterInit();
    lenseSideBarInit();
    accordionContentInit();
    specTableFloat();
});

const mainMenuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-util",
    desktopMethod: "insertBefore",
    breakpoint: 768,
}).watch();

const subMenuMapping = new MappingListener({
    selector: ".header-sub-menu",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-sub-menu-wrapper",
    desktopMethod: "appendTo",
    breakpoint: 768,
}).watch();

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("body").css("pointer-events", "none");
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed,
        function () {
            $("body").css("pointer-events", "");
        }
    );
};

function loadYoutubeApi() {
    var tag = document.createElement("script");

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

window.onYouTubeIframeAPIReady = function () {
    initYoutubePlayer();
};

function initYoutubePlayer() {
    $(".yt-video-player").each(function () {
        let _this = $(this);
        let videoId = _this.attr("data-video-id");
        let id = _this.attr("id");
        let player = new YT.Player(id, {
            videoId: videoId,
            playerVars: {
                autoplay: 0,
                controls: 0,
                disablekb: 1,
                enablejsapi: 1,
                fs: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                playsinline: 1,
                rel: 0,
                mute: 1,
            },
            events: {
                onReady: function (e) {
                    e.target.playVideo(0);
                },
                onStateChange: function (e) {
                    e.target.playVideo(0);
                },
            },
        });
    });
}

function onScrollInit() {
    var lastScrollTop = $(".global-header").height();
    var header = $(".global-header");
    $(window).on("scroll", function () {
        var scrollTop = window.pageYOffset || $(".global-header").height();
        $("body").toggleClass("ui-hidden", scrollTop > lastScrollTop);
        lastScrollTop =
            scrollTop > $(".global-header").height()
                ? scrollTop
                : $(".global-header").height();
        // console.log("🚀 ~ file: main.js:117 ~ scrollTop", scrollTop);
        // console.log("🚀 ~ file: main.js:118 ~ lastScrollTop", lastScrollTop);
    });

    header.toggleClass(
        "on-following",
        $(window).scrollTop() > $(".global-header").height()
    );

    $(window).on("scroll", function () {
        header.toggleClass(
            "on-following",
            $(window).scrollTop() > $(".global-header").height()
        );
    });
}

function searchToggle() {
    $(".search-toggle").on("click", function () {
        if ($(".search-modal").hasClass("is-opened")) {
            $(".search-modal")
                .velocity("stop")
                .velocity(
                    {
                        opacity: 0,
                        transform: ["translateY(10%)"],
                    },
                    {
                        duration: 300,
                        easing: GLOBAL.easing,
                        complete: function () {
                            $(this).removeClass("is-opened");
                            $(this).css({
                                display: "none",
                            });
                        },
                    }
                );
            $(".search-page-overlay").fadeToggle();
        } else {
            $(".search-modal")
                .css({
                    display: "block",
                })
                .velocity("stop")
                .velocity(
                    {
                        opacity: 1,
                        transform: ["translateY(0%)", "translateY(10%)"],
                    },
                    {
                        duration: 300,
                        delay: 90,
                        easing: GLOBAL.easing,
                        complete: function () {
                            $(this).addClass("is-opened");
                        },
                    }
                );
            $(".search-page-overlay").fadeToggle();
        }
    });
    $(".search-close").on("click", function () {
        $(".search-toggle").trigger("click");
    });
    $(".search-page-overlay").on("click", function () {
        $(".search-modal")
            .velocity("stop")
            .velocity(
                {
                    opacity: 0,
                    transform: ["translateY(10%)"],
                },
                {
                    duration: 300,
                    easing: GLOBAL.easing,
                    complete: function () {
                        $(this).removeClass("is-opened");
                        $(this).css({
                            display: "none",
                        });
                    },
                }
            );
        $(this).fadeToggle();
    });
}

function headerSubMenuToggle() {
    $(".header-sub-menu-toggle").on("mouseenter", function () {
        $(".header-sub-menu")
            .css({
                display: "block",
            })
            .velocity("stop")
            .velocity(
                {
                    opacity: 1,
                    transform: ["translateY(0%)", "translateY(10%)"],
                },
                {
                    duration: 300,
                    delay: 90,
                    easing: GLOBAL.easing,
                    complete: function () {
                        $(this).addClass("is-opened");
                        $(this).on("mouseleave", function () {
                            $(this)
                                .velocity("stop")
                                .velocity(
                                    {
                                        opacity: 0,
                                        transform: ["translateY(10%)"],
                                    },
                                    {
                                        duration: 300,
                                        easing: GLOBAL.easing,
                                        complete: function () {
                                            $(this).removeClass("is-opened");
                                            $(this).css({
                                                display: "none",
                                            });
                                            $(".sub-menu-overlay").fadeToggle();
                                        },
                                    }
                                );
                        });
                    },
                }
            );
        $(".sub-menu-overlay").fadeToggle();
        $(".sub-menu-overlay").on("click", function () {
            $(".header-sub-menu")
                .velocity("stop")
                .velocity(
                    {
                        opacity: 0,
                        transform: ["translateY(10%)"],
                    },
                    {
                        duration: 300,
                        easing: GLOBAL.easing,
                        complete: function () {
                            $(this).removeClass("is-opened");
                            $(this).css({
                                display: "none",
                            });
                            $(".sub-menu-overlay").fadeToggle();
                        },
                    }
                );
        });
    });
}

function newsSelectorTrigger() {
    $(".news-top-selector-trigger").on("click", function (e) {
        e.preventDefault();
        $(".news-top-selector-list-container").fadeToggle();
        $(".news-top-selector-overlay").fadeToggle();
    });
    $(".news-top-selector-overlay").on("click", function () {
        $(this).fadeToggle();
        $(".news-top-selector-list-container").fadeToggle();
    });
    $(".news-top-selector-close").on("click", function (e) {
        $(".news-top-selector-trigger").trigger("click");
    });
}

function accordionContentInit() {
    $(".l-accordion-button a, .l-accordion__button a").each(function () {
        var btnTrigger = $(this);
        btnTrigger.on("click", function (e) {
            e.preventDefault();
            let accordion = btnTrigger.closest(".l-accordion");
            if (accordion.hasClass("is-closed")) {
                accordion
                    .find(".l-accordion-wrapper, .l-accordion__wrapper")
                    .velocity(
                        {
                            height: accordion
                                .find(
                                    ".l-accordion-container,.l-accordion__container"
                                )
                                .height(),
                        },
                        {
                            duration: 300,
                            easing: GLOBAL.easing,
                            complete: function () {
                                $(this).attr("style", "");
                                accordion.removeClass("is-closed");
                                accordion.addClass("is-opened");
                                accordion
                                    .find(
                                        ".l-accordion-veil, .l-accordion__veil"
                                    )
                                    .velocity(
                                        { opacity: 0 },
                                        {
                                            duration: 300,
                                            easing: "linear",
                                            complete: function () {
                                                btnTrigger.text(
                                                    btnTrigger.attr(
                                                        "data-close-label"
                                                    )
                                                );
                                                btnTrigger.removeClass(
                                                    "is-disabled"
                                                );
                                            },
                                        }
                                    );
                            },
                        }
                    );
            } else {
                accordion
                    .find(".l-accordion-wrapper, .l-accordion__wrapper")
                    .velocity(
                        {
                            height: accordion
                                .find(
                                    ".l-accordion-wrapper, .l-accordion__wrapper"
                                )
                                .attr("data-closed-height"),
                        },
                        {
                            duration: 300,
                            easing: GLOBAL.easing,
                            complete: function () {
                                var e;
                                accordion.removeClass("is-opened");
                                accordion.addClass("is-closed");
                                $(this).css("height", "");
                            },
                        }
                    );
                accordion
                    .find(".l-accordion-veil, .l-accordion__veil")
                    .velocity(
                        { opacity: [1, 0] },
                        {
                            duration: 300,
                            easing: "linear",
                            complete: function () {
                                btnTrigger.text(
                                    btnTrigger.attr("data-original-label")
                                );
                                btnTrigger.removeClass("is-disabled");
                            },
                        }
                    );
            }
        });
    });
}

function specTableFloat() {
    try {
        var float = new StickySidebar(".p-spec-table__float__inner", {
            containerSelector: ".p-spec-table-parent",
            resizeSensor: !0,
        });
    } catch (error) {}
}

function sideBarInit() {
    try {
        var sidebar = new StickySidebar(".product-detail-sidebar", {
            containerSelector: ".product-detail-sidebar-container",
            innerWrapperSelector: ".product-detail-sidebar-inner",
            resizeSensor: !0,
        });

        var lavalampElm = document.querySelector(".lavalamp");
        var positionLavalamp = function (activeElm) {
            lavalampElm.style.top = activeElm.elm.offsetTop + "px";
        };
        var sidebarMenuSpy = document.querySelector(
            ".product-detail-sidebar nav ul"
        );
        var ms = new MenuSpy(sidebarMenuSpy, {
            callback: function (currentItem) {
                positionLavalamp(currentItem);
                var target = $(currentItem.target);
                if (target.hasClass("has-dark-ui")) {
                    $(".product-detail-sidebar").attr("data-ui-color", "dark");
                } else {
                    $(".product-detail-sidebar").attr("data-ui-color", "light");
                }
            },
        });
    } catch (error) {}

    $(document).on("click", "a.scroll-trigger", function (e) {
        scrollToElement($(this).attr("href"), 750);
        if ($(window).width() < 767) {
            $(".sidebar-list nav").slideToggle();
            $(".sidebar-trigger").toggleClass("active");
        }
    });

    $(".sidebar-trigger").on("click", function () {
        $(".sidebar-list nav").slideToggle();
        $(this).toggleClass("active");
    });
}

function lenseSideBarInit() {
    try {
        var sidebar = new StickySidebar(".lens-search-side", {
            containerSelector: ".lens-search-container",
            innerWrapperSelector: ".lens-search-side-inner",
            resizeSensor: !0,
        });
    } catch (error) {}
}

function lenseFilterInit() {
    $(".lens-item").each(function (index) {
        $(this).attr("id", `index-${index}`);
        // $(this).hide()
    });
    let filterLogs = {};
    let filterItemList = $('.lens-search-side input[type="checkbox"]');

    filterItemList.each(function () {
        let filterItem = $(this);
        let filterGroup = filterItem.attr("name").toLocaleLowerCase();
        let filterVal = filterItem.attr("value").toLocaleLowerCase();
        filterItem.on("click", function () {
            if (filterItem.prop("checked")) {
                $(`input[name=${filterGroup}]`)
                    .not(this)
                    .prop("checked", false);
                filterLogs[filterGroup] = filterVal;
            } else {
                delete filterLogs[filterGroup];
            }
            lenseSearch(filterLogs);
        });
    });

    $(".search-cond-toggle").each(function () {
        let trigger = $(this).find(".search-cond-toggle-trigger");
        let body = $(this).find(".search-cond-toggle-content");
        trigger.on("click", function () {
            body.slideToggle();
        });
    });

    $("#lensSearchOpener").on("click", function () {
        $(".lens-search-side").fadeToggle();
    });

    $(".lens-search-side-header-close").on("click", function () {
        $(".lens-search-side").fadeToggle();
    });

    $("#clear a").on("click", function (e) {
        e.preventDefault();
        filterItemList.filter(":checked").each(function () {
            $(this).prop("checked", false);
        });
        $(this).removeClass("is-active");
        lenseSearch({});
    });

    function lenseSearch(filters) {
        $(".lens-item").each(function () {
            let attrStatus = {};
            let _this = $(this);
            let isShow;
            $.each(filters, function (str, obj) {
                let dataSet = _this.attr(`data-${str}`);
                let hasOption = false;
                if (dataSet) {
                    let dataSetArray =
                        dataSet.indexOf("|") > -1
                            ? dataSet.split("|")
                            : [dataSet];
                    var isContained = $.inArray(obj, dataSetArray);
                    if (isContained !== -1) {
                        hasOption = true;
                    }
                } else {
                    hasOption = false;
                }
                attrStatus[str] = hasOption;
            });
            $.each(attrStatus, function (str, obj) {
                !obj && (isShow = false);
            });
            isShow = Object.values(attrStatus).every(Boolean);
            if (!isShow) {
                $(_this).hide();
                $(_this).removeClass("is-show");
            } else {
                $(_this).show();
                $(_this).addClass("is-show");
            }
        });
        $(".lens-search-concept").each(function () {
            if ($(this).find(".lens-item.is-show").length > 0) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
        $("#result_sum")
            .fadeIn()
            .find("span")
            .text($(".lens-item.is-show").length);
        if ($(window).width() < 767) {
            $("#view")
                .fadeIn()
                .find(".is-counter")
                .text("(" + $(".lens-item.is-show").length + ")");
            $("#clear a").addClass("is-active");
        } else {
            $("#clear a").addClass("is-active");
        }
        window.dispatchEvent(new Event("resize"));
    }
}

var accessoriesRelationalSlider = new Swiper(
    ".accessories-relational-slider .swiper-container",
    {
        slidesPerView: "auto",
        loop: true,
        grabCursor: true,
        simulateTouch: true,
        navigation: {
            nextEl: ".accessories-relational-slider .swiper-next",
            prevEl: ".accessories-relational-slider .swiper-prev",
        },
    }
);

var productImpressionSlider = new Swiper(
    ".product-impression-slider .swiper-container",
    {
        direction: "horizontal",
        slidesPerView: "auto",
        loop: true,
        lazy: false,
        autoHeight: false,
        grabCursor: true,
        simulateTouch: true,
        centeredSlides: true,
    }
);

let countDetailBanner = $(".product-detail-banner-slider .swiper-slide").length;

if (countDetailBanner > 1) {
    var productDetailBanner = new Swiper(
        ".product-detail-banner-slider .swiper-container",
        {
            slidesPerView: 1,
            loop: true,
            grabCursor: true,
            simulateTouch: true,
            allowTouchMove: true,
            speed: 500,
            autoplay: {
                delay: 7500,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".product-detail-banner-slider .swiper-pagination",
                clickable: true,
                renderBullet: function (e, t) {
                    return (
                        '<div class="' +
                        t +
                        '"><span class="' +
                        t +
                        '-progress"><span></span></span></div>'
                    );
                },
            },
            navigation: {
                nextEl: ".product-detail-banner-slider .swiper-button-next",
                prevEl: ".product-detail-banner-slider .swiper-button-prev",
            },
            effect: "fade",
            fadeEffect: { crossFade: true },
        }
    );

    productDetailBanner.on("init", function () {
        colorChangeUI(this);
    });

    productDetailBanner.on("slideChange", function () {
        colorChangeUI(this);
    });

    const colorChangeUI = function (swiper) {
        const currentSlideIndex = swiper.activeIndex;
        const currentSlide = swiper.slides[currentSlideIndex];
        const uiColor = $(currentSlide).data("ui-color");
        $(".global-header").attr("data-ui-color", uiColor);
        $(".product-detail-banner-slider").attr("data-ui-color", uiColor);
    };
}
